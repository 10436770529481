<template>
  <client-only>
    <transition-root appear as="template" :show="isOpen">
      <dialog-root
        as="div"
        class="relative z-[1000]"
        :open="isOpen"
        @close="!preventAutoClose && close()"
      >
        <div
          class="fixed inset-0 flex overflow-hidden md:p-4 backdrop-blur bg-white/50 transition-all"
        >
          <transition-child
            as="template"
            enter="transition duration-200 ease-out"
            enter-from="opacity-0 scale-95"
            :show="isOpen"
          >
            <app-dialog-close-button
              class="absolute top-2 right-2 md:top-6 md:right-6 z-10"
              @click="close"
            />
          </transition-child>

          <transition-child
            as="template"
            enter="transition duration-200 ease-out"
            enter-from="opacity-0 scale-95"
            :show="isOpen"
          >
            <dialog-panel
              class="relative w-full bg-white shadow-lg overflow-auto p-4 md:rounded-lg"
              data-cy-dialog
              v-bind="$attrs"
            >
              <slot />
            </dialog-panel>
          </transition-child>
        </div>
      </dialog-root>
    </transition-root>
  </client-only>
</template>

<script lang="ts" setup>
import {
  Dialog as DialogRoot,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

defineOptions({
  inheritAttrs: false,
});

const properties = defineProps<{
  modelValue: boolean;
  preventAutoClose?: boolean;
}>();

const emit = defineEmits(["close"]);
const isOpen = useVModel(properties, "modelValue");

const close = () => {
  isOpen.value = false;
  emit("close");
};

watch(() => useRoute().path, close);
</script>
