<template>
  <app-dialog-full-screen v-model="model">
    <p
      class="absolute left-0 right-0 grid h-12 place-content-center bg-white text-subtle"
    >
      {{ title }}
    </p>
    <div id="viewer" class="h-full w-full" />
  </app-dialog-full-screen>
</template>

<script lang="ts" setup>
import type { DocumentPdfViewerDialogProperties } from "../pdf.model";

const model = defineModel<boolean>({ default: false });
const properties = defineProps<DocumentPdfViewerDialogProperties>();

useAdobePDFEmbedApi().read(properties);
</script>
